import React from 'react';
import './App.css';
import logo from './logo.jpg'; // Place your logo image in the src directory
import profileImage from './profile.jpg'; // Place your profile image in the src directory
import { Link } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="left-content">
          <img className="logo" src={logo} alt="Logo" />
          <h1 className="title">Angel Linaa</h1>
          <p className="description">Can I be your new obsession?</p>
          <a href="https://onlyfans.com/angel.linaa/c14" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <button className="button">Come join me<span></span></button>
          </a>
        </div>
        <img className="profile-image" src={profileImage} alt="Profile" />
      </div>
    </div>
  );
}

export default App;
